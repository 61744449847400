tr{
    border-style: none !important;
    border-color: transparent!important;

}
aside.active .nav-item:not(.menu){
    width: 100% !important;
}
aside li{
    width: 100% !important;
}
.dropzone{
    border: 1px dotted gray;
    border-radius: 10px;
    
}
.dropzone p{
    margin: 5vh;
    padding: 0;
    cursor: pointer;
}
.pagination{
    position: relative;
    z-index: 0;
}
 .ant-col-14{
display: none !important;
}
.ant-col-10{
    width: 100% !important;
    max-width: 100% !important;
    flex: auto !important;
}
.alertify .ajs-body .ajs-content{
    color: black;
}
.alertify.ajs-movable:not(.ajs-maximized) .ajs-header{
    color: black;
}
.ck-focused{
    color: black!important;
}
.ck-editor__editable .ck-table-bogus-paragraph {
    color: black;
}
.ck.ck-editor__editable_inline>:first-child{
    color: black;
}